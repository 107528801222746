@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-px {
  @apply px-6 md:px-8;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track-piece {
  background-color: #f3f3f3;
}
::-webkit-scrollbar-thumb {
  background-color: #989898;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3a3a3a;
}

.in-top {
  transform-origin: 50% 0%;
  animation: in-top 300ms ease 0ms 1 forwards;
}
.in-right {
  transform-origin: 100% 0%;
  animation: in-right 300ms ease 0ms 1 forwards;
}
.in-bottom {
  transform-origin: 50% 100%;
  animation: in-bottom 300ms ease 0ms 1 forwards;
}
.in-left {
  transform-origin: 0% 0%;
  animation: in-left 300ms ease 0ms 1 forwards;
}

.out-top {
  transform-origin: 50% 0%;
  animation: out-top 300ms ease 0ms 1 forwards;
}
.out-right {
  transform-origin: 100% 50%;
  animation: out-right 300ms ease 0ms 1 forwards;
}
.out-bottom {
  transform-origin: 50% 100%;
  animation: out-bottom 300ms ease 0ms 1 forwards;
}
.out-left {
  transform-origin: 0% 0%;
  animation: out-left 300ms ease 0ms 1 forwards;
}

@keyframes in-top {
  from {
    transform: rotate3d(-1, 0, 0, 90deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}
@keyframes in-right {
  from {
    transform: rotate3d(0, -1, 0, 90deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}
@keyframes in-bottom {
  from {
    transform: rotate3d(1, 0, 0, 90deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}
@keyframes in-left {
  from {
    transform: rotate3d(0, 1, 0, 90deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes out-top {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(-1, 0, 0, 104deg);
  }
}
@keyframes out-right {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(0, -1, 0, 104deg);
  }
}
@keyframes out-bottom {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(1, 0, 0, 104deg);
  }
}
@keyframes out-left {
  from {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(0, 1, 0, 104deg);
  }
}
